<template>
  <div id="contact-page">
    <b-navbar class="other" variant="faded" type="light" toggleable="lg" fixed="top">
      <b-navbar-toggle target="nav_text_collapse"></b-navbar-toggle>
      <b-navbar-brand :to="'/'">
        <img :src="Group_49" alt="Mongolia" class="mr-4">
        <span class="text-brand">CONTACT</span>
      </b-navbar-brand>
    </b-navbar>
    <div class="content">
      <div class="ig" ref="ig">
        <ImagesBoard
          :grid="true"
          :rows="4"
          :columns="2"
          @calculatesize="onImagesBoardCalculateSize"
        />
      </div>
      <div class="content-detail">
        <div id="getting-to-mongolia">
          <div class="getting-left">
            <div class="contact__img-wrap" data-aos="zoom-out" data-aos-delay="600">
              <picture>
                <source data-srcset="assets/img/about-img1-@480.jpg" media="(max-width: 480px)">
                <img alt="Picture" :src="Yamnii_logo_ENG_New">
              </picture>
            </div>
            <div class="contact__paragraph">
              <p
                class="contact__paragraph"
                style="white-space: pre;"
                data-aos="zoom-out"
                data-aos-delay="900"
              >
                <span class="number">
                  <img alt="Picture" :src="Path_12406"> Government Building 2, United Nation’s street 5/2
                  Chingeltei district, Ulaanbaatar
                </span>
              </p>
              <p class="contact__paragraph" data-aos="zoom-out" data-aos-delay="1200">
                <span class="number">
                  <img alt="Picture" :src="Path_13519">
                </span> (976-51) 261966
              </p>
              <p class="contact__paragraph" data-aos="zoom-out" data-aos-delay="1400">
                <span class="number">
                  <img alt="Picture" :src="Path_13520">
                </span> www.mne.mn
              </p>
              <div class="footer" data-aos="zoom-out" data-aos-delay="1600">
                <hr style="margin-top:35%;width:190%;">
                <h2 style="font-size:25px;">Partner</h2>
                <img alt="Picture" :src="Union_620">
              </div>
            </div>
          </div>
          <div class="getting-right">
            <form @submit.prevent="onSubmit">
              <input
                type="text"
                class="form-control"
                placeholder="Your name"
                v-model="form.name"
                required
              >
              <br>
              <input
                type="email"
                class="form-control"
                id="exampleInputEmail1"
                placeholder="Enter email"
                v-model="form.email"
                required
              >
              <br>
              <textarea
                class="form-control"
                type="text"
                rows="5"
                placeholder="Your message to us"
                v-model="form.message"
                required
              ></textarea>
              <br>
              <input
                type="submit"
                value="SEND"
                class="btn btn-danger btn-block"
              >
            </form>
          </div>
        </div>
      </div>
    </div>
    <NavrightHome/>
  </div>
</template>

<script>
import axios from 'axios';
import("aos").then(AOS => AOS.init());
import NavrightHome from "@/components/NavrightHome";
import Group_49 from "@/assets/img/contact/Group 49.svg";
import Group_590 from "@/assets/img/contact/Group 590.png";
import Yamnii_logo_ENG_New from "@/assets/img/contact/Yamnii logo ENG New.svg";
import Path_12406 from "@/assets/img/contact/Path 12406.png";
import Path_13519 from "@/assets/img/contact/Path 13519.png";
import Path_13520 from "@/assets/img/contact/Path 13520.png";
import Union_620 from "@/assets/img/contact/Union 620.svg";
import ImagesBoard from "@/components/ImagesBoard";

export default {
  name: "ContactPage",
  components: {
    NavrightHome,
    ImagesBoard
  },
  data() {
    return {
      Group_590,
      Group_49,
      Yamnii_logo_ENG_New,
      Path_12406,
      Path_13519,
      Path_13520,
      Union_620,
      form: {
        name: "",
        email: "",
        message: ""
      }
    };
  },
  methods: {
    onImagesBoardCalculateSize(size) {
      this.$refs.ig.style.width = size.width + "px";
    },
    onSubmit() {
      axios
        .post("/.netlify/functions/sendmail", this.form)
        .then(() => {
          this.$toasted.show("Thank you, your message has been sent.", {
            type: "success",
            position: "bottom-right",
            duration: 1000
          });

          this.form.name = "";
          this.form.email = "";
          this.form.message = "";
        })
        .catch(err => {
          console.error(err);
          this.$toasted.show("Something went wrong.", {
            type: "error",
            position: "bottom-right",
            duration: 1000
          });
        });
    }
  }
};
</script>

<style lang="scss" scoped>
#contact-page {
  .other {
    align-items: center;
    width: 24.75%;
    background-color: white;
    .text-brand {
      margin-top: 20px;
      color: red;
      font-size: 24px;
      font-weight: bold;
    }
  }
  .fixed-top {
    z-index: 10;
  }
  .mc {
    margin: auto;
    .nav-item {
      padding-left: 25px;
      padding-right: 25px;
      .nav-link {
        color: #e13036;
        padding-top: 0px;
        &:hover {
          color: red;
        }

        &.router-link-exact-active {
          span {
            color: #e13036;
            padding-bottom: 5px;
            border-bottom: 3px solid #e13036;
          }
        }
      }
    }
  }

  .content {
    display: flex;
    .ig {
      position: fixed;
      width: 24.46%;
      height: calc(100vh - 102px);
      box-sizing: content-box;
      padding-top: 102px;
      background-color: #999;
      overflow: hidden;
    }
    .content-detail {
      padding-left: 24.46%;
      width: 100%;
      height: 100vh;
      .contact__paragraph {
        margin-top: 3%;
      }
    }
  }
  #getting-to-mongolia {
    width: 100%;
    height: 50%;
    display: -webkit-box;
    display: flex;
    margin-top: 5%;
    top: 0;
    left: 0;
  }
  #getting-to-mongolia .getting-left {
    width: 50%;
    padding: 50px;
  }
  #getting-to-mongolia .getting-right {
    width: calc(50% - 70px);
    padding: 40px;

    color: #ffff;
    height: 130%;
  }
}

@media only screen and (min-width: 1920px) {
  .other {
    align-items: center;
    width: 24.75%;
    background-color: red;
  }
  .content-detail {
    margin-top: -1%;
    top: 0;
  }
}
@media only screen and (min-width: 1920px) {
  #contact-page {
    .other {
      align-items: center;
      width: 17.5%;
    }
  }
}
</style>
